<template>
  <div class="menu-item">
    <router-link
      v-if="item.type == 'item'"
      class="primary-a d-flex align-items-center"
      v-bind:class="{ 'route-primary-active':active }"
      :to="{ name: item.route, params: item.params }"
    >
      <div class="icon-route">
        <span>
          <i :class="item.icon" aria-hidden="true"></i>
        </span>
      </div>
      <div class="menu-primary">
        <span>{{item.label}}</span>
      </div>
    </router-link>
    <div class="group-items" v-if="item.type == 'list'" v-bind:class="{ 'no-padding':listOpened }">
      <div class="group-name d-flex align-items-center" v-on:click="toogleList()">
        <div class="icon-route">
          <span>
            <i :class="item.icon" aria-hidden="true"></i>
          </span>
        </div>
        <div class="menu-primary">
          <span>{{item.label}}</span>
        </div>
        <i
          class="fa fa-angle-down angle"
          v-bind:class="{ 'angle-opened':listOpened }"
          aria-hidden="true"
        ></i>
      </div>
      <div class="group-items-list" v-bind:class="{ 'group-collapsed':listOpened }">
        <router-link
          v-for="subItem in item.list"
          v-bind:key="subItem.label"
          class="primary-a d-flex align-items-center"
          v-bind:class="{ 'route-primary-active':routeActive(subItem.route) }"
          :to="{ name: subItem.route, params: subItem.params }"
        >
          <div class="icon-route">
            <span>
              <i :class="subItem.icon" aria-hidden="true"></i>
            </span>
          </div>
          <div class="menu-primary">
            <span>{{subItem.label}}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "menu-item",
  data() {
    return {
      active: false,
      listOpened: false
    };
  },
  props: {
    item: Object
  },
  watch: {
    $route() {
      let currentRoute = this.$route.name ? this.$route.name : "Home";
      this.active = currentRoute == this.item.route;
    }
  },
  mounted(){
    let currentRoute = this.$route.name ? this.$route.name : "Home";
    this.active = currentRoute == this.item.route;
  },
  methods: {
    toogleList() {
      this.listOpened = !this.listOpened;
    },
    subrouteActive(route) {
      let find = false;
      if(this.item.list){
        this.item.list.forEach(element => {
          if (element.route == route) find = true;
        });
      }
      return find;
    },
    routeActive(route){
      let currentRoute = this.$route.name;
      return currentRoute == route;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/general.scss";

.menu-item {
  a {
    color: #641E16;
  }
  .no-padding{
    padding-bottom: 0 !important;
  }
  .group-items {
    color: #641E16;
    padding: 8px 0px 8px 10px;
    .group-name {
      position: relative;
      i.angle {
        position: absolute;
        right: 8px;
        transition: transform 0.5s;
      }
      i.angle-opened {
        transform: rotateZ(180deg);
      }
    }
    .group-name:hover {
      color: #994e52;
      cursor: pointer;
    }
    .group-items-list {
      padding-left: 0.8vw;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
      .menu-primary {
        font-size: 14px !important;
      }
      .icon-route {
        width: 14px !important;
        font-size: 12px !important;
      }
    }
    .group-collapsed {
      margin-top: 8px;
      max-height: 350px;
    }
  }
  .primary-a {
    text-decoration: none;
    padding: 8px 0px 8px 10px;
  }
  .primary-a:hover {
    color: #994e52;
  }
  .menu-primary {
    font-size: 15px;
  }
  .icon-route {
    width: 22px;
    font-size: 17px;
  }
  .route-primary-active {
    color: white;
    font-weight: 500;
    // border-right: 4.2px solid $page-background;
    background-color: $primary-red-light;
  }
  .icon-route {
    margin-right: 0.6vw;
  }
}
</style>