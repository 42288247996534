import axios from "@/api/axios";
import Swal from "sweetalert2";
import { bus } from "@/main";
import moment from "moment";

export default {
    formatStringDateToUs(dataString) {
        let res = dataString.split('/');
        if (res.length == 3) {
            return `${res[2]}-${res[1]}-${res[0]}`
        } else {
            return dataString;
        }
    },
    formatOnlyCharsString(value) {
        let res = value;
        res = res.split('-').join("");
        res = res.split('/').join("");
        res = res.split('.').join("");
        res = res.split(',').join("");
        res = res.split('(').join("");
        res = res.split(')').join("");
        res = res.split(':').join("");
        res = res.split(' ').join("");

        return res;
    },
    getPaginateQuery(target, urlComplement) {
        return axios.get(`/api/${target}?${urlComplement}`)
    },
    getUrlBuildQuery(perPage, currentPage, filters, includes, sortBy = '') {
        let includesStr = '';
        if (includes.length > 0) {
            includesStr = 'include=';
            includes.forEach((el, index) => includesStr += `${el}` + (index < (includes.length - 1) ? ',' : ''));
        }

        let filtersStr = '';
        let filterArr = Object.entries(filters);
        filterArr.forEach(el => {
            //todo: tratar array
            if (el[1] && el[1] != '') {
                filtersStr += `filter[${el[0]}]=${el[1]}&`
            }
        });

        if (sortBy != '') {
            sortBy = `sort=${sortBy}&`
        }

        return `${sortBy}${includesStr}&page[number]=${currentPage}&page[size]=${perPage}&${filtersStr}`
    },
    formatMoney(value) {
        return parseFloat(value).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
        });
    },
    formatDate(date) {
        return moment(date).format("DD/MM/YYYY");
    },
    formatDateOnly(date) {
        return moment(date).format("MM/YYYY");
    },
    formatDateTime(date) {
        return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    formatBRDateToUs(stringDate) {
        if (stringDate.length != 10) {
            return false;
        }
        var day = stringDate.split("/")[0];
        var month = stringDate.split("/")[1];
        var year = stringDate.split("/")[2];

        var usDate = year + '-' + ("0" + month).slice(-2) + '-' + ("0" + day).slice(-2);

        return usDate;
    },
    currentDateBr() {
        return moment(new Date()).format("DD/MM/YYYY");
    },
    validateDateBR(stringDate) {
        var usDate = this.formatBRDateToUs(stringDate);

        var date = new Date(usDate);

        return date != 'Invalid Date';
    },
    getObjToFormData(dataObj) {
        let arrObj = Object.entries(dataObj);
        let formData = new FormData();
        arrObj.forEach(el => {
            if (Array.isArray(el[1])) {
                el[1].forEach(subEl => {
                    formData.append(el[0] + '[]', subEl);
                });
            }
            else {
                formData.append(el[0], el[1]);
            }
        })

        return formData;
    },
    saveCustomFormDataObject(target, secondTarget, dataObj, type, itemLabel, genderLabel) {
        bus.$emit("changeLoading", true);
        let data = this.getObjToFormData(dataObj);
        if (type == 'edit') {
            data.append('_method', 'PUT');
        }
        return new Promise((resolve, reject) => {
            let promise =
                type == "create" ?
                    axios.post(`/api/${target}/${secondTarget}`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'enctype': 'multipart/form-data'
                        }
                    }) :
                    axios.post(
                        `/api/${target}/${secondTarget}/${dataObj.id}`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'enctype': 'multipart/form-data'
                        }
                    });

            promise
                .then(response => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: type == "create" ?
                            `${itemLabel} cadastrad${genderLabel}` : `${itemLabel} alterad${genderLabel} com sucesso`,
                        icon: "success",
                        onClose: () => {
                            resolve()
                        }
                    });
                })
                .catch(error => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: "Ocorreu um erro.",
                        icon: "error",
                        onClose: () => {
                            reject()
                        }
                    });
                });
        })
    },
    saveFormDataObject(target, dataObj, type, itemLabel, genderLabel) {
        bus.$emit("changeLoading", true);
        let data = this.getObjToFormData(dataObj);
        if (type == 'edit') {
            data.append('_method', 'PUT');
        }
        return new Promise((resolve, reject) => {
            let promise =
                type == "create" ?
                    axios.post(`/api/${target}`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'enctype': 'multipart/form-data'
                        }
                    }) :
                    axios.post(
                        `/api/${target}/${dataObj.id}`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'enctype': 'multipart/form-data'
                        }
                    });

            promise
                .then(response => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: type == "create" ?
                            `${itemLabel} cadastrad${genderLabel}` : `${itemLabel} alterad${genderLabel} com sucesso`,
                        icon: "success",
                        onClose: () => {
                            resolve()
                        }
                    });
                })
                .catch(error => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: "Ocorreu um erro.",
                        icon: "error",
                        onClose: () => {
                            reject()
                        }
                    });
                });
        })
    },
    saveCustomDataObject(target, secondTarget, dataObj, type, itemLabel, genderLabel) {
        bus.$emit("changeLoading", true);
        return new Promise((resolve, reject) => {
            let promise =
                type == "create" ?
                    axios.post(`/api/${target}/${secondTarget}`, dataObj) :
                    axios.put(
                        `/api/${target}/${secondTarget}/${dataObj.id}`,
                        dataObj
                    );
            promise
                .then(response => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: type == "create" ?
                            `${itemLabel} cadastrad${genderLabel}` : `${itemLabel} alterad${genderLabel} com sucesso`,
                        icon: "success",
                        onClose: () => {
                            resolve()
                        }
                    });
                })
                .catch(error => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: "Ocorreu um erro.",
                        icon: "error",
                        onClose: () => {
                            reject()
                        }
                    });
                });
        })
    },
    saveDataObject(target, dataObj, type, itemLabel, genderLabel) {
        bus.$emit("changeLoading", true);
        return new Promise((resolve, reject) => {
            let promise =
                type == "create" ?
                    axios.post(`/api/${target}`, dataObj) :
                    axios.put(
                        `/api/${target}/${dataObj.id}`,
                        dataObj
                    );

            promise
                .then(response => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: type == "create" ?
                            `${itemLabel} cadastrad${genderLabel}` : `${itemLabel} alterad${genderLabel} com sucesso`,
                        icon: "success",
                        onClose: () => {
                            resolve()
                        }
                    });
                })
                .catch(error => {
                    bus.$emit("changeLoading", false);
                    Swal.fire({
                        title: "Ocorreu um erro.",
                        icon: "error",
                        onClose: () => {
                            reject()
                        }
                    });
                });
        })
    },
    getDataObject(target, id) {
        return axios.get(`/api/${target}/${id}`);
    },
    getCreateParams(target) {
        return axios.get(`/api/${target}/create`);
    },
    destroyDataObject(target, dataObj, extraInfo = '', errorMessage = '') {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: "Você tem certeza que deseja excluir esse item?",
                text: extraInfo,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(result => {
                if (result.value) {
                    let promise = axios.delete(
                        `/api/${target}/${dataObj.id}`
                    );
                    bus.$emit("changeLoading", true);
                    promise.then(response => {
                        bus.$emit("changeLoading", false);
                        Swal.fire({
                            title: "Item excluído com sucesso",
                            icon: "success",
                            onClose: () => {
                                resolve(response)
                            }
                        });
                    })
                        .catch(error => {
                            bus.$emit("changeLoading", false);
                            Swal.fire({
                                title: "Ocorreu um erro",
                                text: `${errorMessage}`,
                                icon: "error",
                                onClose: () => {
                                    reject(error)
                                }
                            });
                        })
                }
            });
        })
    },
    showErrorsForm(errors) {
        let html = "Preencha todos os campos obrigatórios.";
        if (errors.length < 4) {
            html = "";
            errors.forEach(el => (html += el.message + "<br>"));
        }
        Swal.fire({
            title: "Atenção",
            html: html,
            icon: "warning"
        });
    },
    verifyUniqueCred(target, cred, credType,) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/${target}`, {
                    cred: cred
                })
                .then(response => {
                    if (response.data.exists == 1) {
                        Swal.fire({
                            title: `Parece que esse ${credType} já existe`,
                            icon: "info",
                            onClose: () => {
                                resolve(response)
                            }
                        });
                        return false;
                    }
                })
                .catch(error => {
                    Swal.fire({
                        title: "Ocorreu um erro.",
                        icon: "error",
                        onClose: () => {
                            reject(error)
                        }
                    });
                });
        })
    },
    validateCpf(data) {
        var cpf = data;
        let sum;
        let rest;
        cpf = cpf.replace(/\D/g, '');

        sum = 0;
        if (cpf == "00000000000") {
            return false;
        }
        for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        rest = (sum * 10) % 11;

        if ((rest == 10) || (rest == 11)) rest = 0;
        if (rest != parseInt(cpf.substring(9, 10))) {
            return false;
        }
        sum = 0;
        for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        rest = (sum * 10) % 11;

        if ((rest == 10) || (rest == 11)) {
            rest = 0;
        };

        if (rest != parseInt(cpf.substring(10, 11))) {
            return false;
        }
        else {
            return true;
        }
    },

    isAdminLogged() {
        return localStorage.getItem('auth.user_id') && localStorage.getItem('auth.user_id') != '' && localStorage.getItem('auth.user_role') == 'admin';
    },

    getLoggedRole() {
        return localStorage.getItem('auth.user_role');
    },

    getLoggedId() {
        return localStorage.getItem('auth.user_id');
    }
}

