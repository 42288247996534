import Vue from "vue";
import App from "./App.vue";
import VueTheMask from 'vue-the-mask'
import 'font-awesome/css/font-awesome.min.css'
import './imports/BootstrapVue.js'
import Navigator from './components/menu/BreadcrumbComponent.vue'
import router from "./router"
import ToggleButton from 'vue-js-toggle-button'
// import Autocomplete from '@trevoreyre/autocomplete-vue';
// import '@trevoreyre/autocomplete-vue/dist/style.css';

Vue.use(VueTheMask);
// Vue.use(Autocomplete);
Vue.use(ToggleButton)
Vue.component('navigator', Navigator)

export const bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
