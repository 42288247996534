import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

function lazyLoad(view:string) {
  return () =>
      import (`@/views/${view}.vue`)
}

const ifAuthenticated = (to:any, from:any, next:any) => {
  if (localStorage.getItem('auth.user_id') && localStorage.getItem('auth.user_id') != '') {
      next();
      return;
  }
  next('/login');
}

const ifAdminAuthenticated = (to:any, from:any, next:any) => {
  if (localStorage.getItem('auth.user_id') && localStorage.getItem('auth.user_id') != '' && localStorage.getItem('auth.user_role') == 'admin') {
      next();
      return;
  }
  next('/login');
}

const ifAlreadyAuthenticated = (to:any, from:any, next:any) => {
  if (localStorage.getItem('auth.user_id') && localStorage.getItem('auth.user_id') != '') {
      next('/dashboard/clients');
      return;
  }
  next();
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: lazyLoad('Login'),
    beforeEnter: ifAlreadyAuthenticated
  },
  {
    path: "/login",
    name: "Login",
    component: lazyLoad('Login'),
    beforeEnter: ifAlreadyAuthenticated
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: { render: (h: any) => h('router-view') },
    meta: { layout: 'admin-layout' },
    children: [
      {
        path: "clients",
        name: "dashboard.clients",
        component: lazyLoad('clients/ListClients'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "client",
        name: "dashboard.client",
        component: lazyLoad('clients/Client'),
        props: true,
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "wallets",
        name: "dashboard.wallets",
        component: lazyLoad('wallets/ListWallets'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "wallet",
        name: "dashboard.wallet",
        component: lazyLoad('wallets/Wallet'),
        props:true, 
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "calls",
        name: "dashboard.calls",
        component: lazyLoad('calls/ListCalls'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "call",
        name: "dashboard.call",
        component: lazyLoad('calls/Call'),
        props:true, 
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "user", 
        name: "dashboard.user",
        component: lazyLoad('users/User'),
        props: true, 
        beforeEnter: ifAuthenticated,
        meta: {layout: 'admin-layout'},
      },
      {
        path: "users",
        name: "dashboard.users",
        component: lazyLoad('users/ListUsers'),
        beforeEnter: ifAuthenticated,
        meta: {layout: 'admin-layout'}
      },
      {
        path: "shipments",
        name: "dashboard.shipments",
        component: lazyLoad('shipments/ListShipments'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "shipment",
        name: "dashboard.shipment",
        component: lazyLoad('shipments/Shipment'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "returns",
        name: "dashboard.returns",
        component: lazyLoad('returns/ListReturns'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "return",
        name: "dashboard.return",
        component: lazyLoad('returns/Return'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "detailed-return",
        name: "dashboard.detailed-return",
        component: lazyLoad('returns/DetailedReturn'),
        props: true, 
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-institution",
        name: "dashboard.report_institution",
        component: lazyLoad('reports/ReportInstitution'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-institution-create",
        name: "dashboard.report_institution_create",
        component: lazyLoad('reports/ReportInstitutionCreate'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-operator",
        name: "dashboard.report_operator",
        component: lazyLoad('reports/ReportOperator'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-cancel",
        name: "dashboard.report_cancel",
        component: lazyLoad('reports/ReportCancel'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-safety",
        name: "dashboard.report_safety",
        component: lazyLoad('reports/ReportSafety'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-general",
        name: "dashboard.report_general",
        component: lazyLoad('reports/ReportGeneral'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "home",
        name: "dashboard.finances",
        component: lazyLoad('financial/ListFinances'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "cancel-donation",
        name: "dashboard.cancel-donation",
        component: lazyLoad('operations/CancelDonation'),
        props: true, 
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "update-donation",
        name: "dashboard.update-donation",
        component: lazyLoad('operations/UpdateDonation'),
        props: true, 
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' },
      },
      {
        path: "report-final-payments",
        name: "dashboard.report_final_payments",
        component: lazyLoad('reports/ReportFinalPayment'),
        beforeEnter: ifAuthenticated,
        meta: { layout: 'admin-layout' }
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
