<template>
  <div class="navigator-component container-fluid card-shadow">
    <div class="row">
      <div class="col-12 d-flex">
        <div class="label-route d-flex" v-for="(item, index) in items" v-bind:key="item.text">
          <div class="route-content">
            <router-link :to="{ name: item.route }" v-if="!item.active">{{item.text}}</router-link>
          </div>
          <span class="active-route" v-if="item.active">{{item.text}}</span>
          <div class="separator" v-if="index < (items.length - 1)"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigator-component",
  props: {
    items: {}
  },
  mounted() {
  }
};
</script>

<style lang="scss">
@import "@/styles/general.scss";
.navigator-component {
  background-color: #fff;
  padding-top: 12px; 
  padding-bottom: 12px; 
  margin-bottom: 3.2vh;
  .active-route{
    color: $primary-red;
    font-weight: bold;
  }
  .label-route {
    color: #6c757d;
    font-size: 18px;
    a {
      color: $dark-grey;
      text-decoration: none;
    }
    a:hover {
      color: $medium-grey;      
    }
    .separator{
      margin: 0 8px;
    }
  }
}
</style>