import axios from '@/api/axios'

export default {
    login(login:string, password:string) {
        return new Promise(function(resolve, reject){
            axios.post('/api/login', { login, password })
            .then((response:any) => {
                localStorage.setItem('auth.token',response.data.access_token);
                localStorage.setItem('auth.expires_in',response.data.expires_in);
                localStorage.setItem('auth.user_id',response.data.user.id);
                localStorage.setItem('auth.user_role',response.data.role);
                localStorage.setItem('auth.user_name',response.data.user.name);
                resolve(response);
            })
            .catch(error => {
                reject(error);
              });
        })
    },
    logout(){
        return new Promise(function(resolve, reject){
            axios.delete('/api/logout')
            .then( response => {
                localStorage.setItem('auth.token', '');
                localStorage.setItem('auth.expires_in', '');
                localStorage.setItem('auth.user_id', '');
                localStorage.setItem('auth.user_role', '');
                localStorage.setItem('auth.user_name', '');
                
                resolve(response);
            })
            .catch( error => {
                reject(error);
            })
        })
    }
}

