import Vue from 'vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import {
  BCollapse,
  BButton,
  BCard,
  BFormInput,
  BBreadcrumb,
  BFormFile,
  BAlert,
  BTable,
  BPagination,
  BSpinner,
  BFormDatepicker,
  BToast,
  BModal,
  VBModal,
  BTabs,
  BTab
} from 'bootstrap-vue'

Vue.directive('b-modal', VBModal)
Vue.directive('b-toast', BToast)

Vue.component('b-collapse', BCollapse)
Vue.component('b-button', BButton)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-card', BCard)
Vue.component('b-form-input', BFormInput)
Vue.component('b-breadcrumb', BBreadcrumb)
Vue.component('b-form-file', BFormFile)
Vue.component('b-alert', BAlert)
Vue.component('b-table', BTable)
Vue.component('b-pagination', BPagination)
Vue.component('b-spinner', BSpinner)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-modal', BModal)