<template>
  <div class="admin-template">
    <div class="sidebar-admin">
      <div class="menu">
        <menu-item-component v-for="(item, index) in menu_items" :item="item" v-bind:key="index"></menu-item-component>
      </div>
      <div
        class="logout-content d-flex justify-content-center align-items-center"
        v-on:click="logout()"
      >
        <i class="fa fa-power-off" aria-hidden="true"></i>
        <span>Sair</span>
      </div>
    </div>
    <div class="top-bar-admin d-flex justify-content-between align-items-center">



      <div class="logo d-flex justify-content-flex-start align-items-flex-start">
        <img style="width: 60px;  height: 60px; margin-left:10px; " v-bind:src="logo" />
          <label   style="color:#994e52; align-self:center; font-size: 20px; margin-left:10px;padding-top: 10px">Mão Amiga</label>
      </div>



      <div class="user-logged d-flex align-items-center justify-content-end">
        <div class="user-name">{{userName}}</div>
        <i class="fa fa-user-circle" aria-hidden="true"></i>
      </div>
    </div>
    <div class="page-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MenuItemComponent from "@/components/menu/MenuItemComponent";
import adminMenu from "@/imports/menu/admin-menu";
import helper from "@/imports/Helpers";
import logo from "@/assets/images/thumbnail.png";
import auth from "@/api/auth";
import { bus } from "@/main";

export default {
  name: "admin-template",
  components: {
    MenuItemComponent
  },
  data() {
    return {
      menu_items: [],
      loggedRole: "",
      userName: {},
      logo
    };
  },
  mounted() {
    this.getUserName();
    this.loggedRole = helper.getLoggedRole();
    adminMenu.list.forEach(el => {
      if (el.roles.includes(this.loggedRole)) {
        let item = el;
        if (item.type == "list") {
          let list = [];
          item.list.forEach(subEl => {
            if (subEl.roles.includes(this.loggedRole)) {
              list.push(subEl);
            }
          });
          item.list = list;
        }
        this.menu_items.push(item);
      }
    });
  },

  methods: {
    getUserName: function() {
      this.userName = localStorage.getItem("auth.user_name");
    },
    logout: function() {
      bus.$emit("changeLoading", true);
      auth
        .logout()
        .then(response => {
          window.location.href = "/";
        })
        .catch(error => {
          Swal.fire({
            title: "Tivemos um problema",
            icon: "error"
          });
        })
        .finally(() => {
          bus.$emit("changeLoading", false);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/general.scss";
@import "@/styles/layouts/admin-layout.scss";
</style>