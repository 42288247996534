<template>
  <div id="app">
    <component v-bind:is="layout"></component>
    <loading loader="dots" color="#994e52" :opacity="opacity" :active.sync="isLoading" is-full-page></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import AdminLayout from "@/layouts/AdminLayout";
import OnlyViewLayout from "@/layouts/OnlyViewLayout";
import "vue-loading-overlay/dist/vue-loading.css";
import { bus } from "@/main";

const default_layout = "only-view-layout";
export default {
  name: "app",
  components: {
    Loading,
    AdminLayout,
    OnlyViewLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    }
  },
  data() {
    return {
      isLoading: false,
      opacity: 0.7
    };
  },
  created() {
    bus.$on("changeLoading", data => {
      this.isLoading = data;
    });
  }
}
</script>

<style lang="scss">
@import "@/styles/general.scss";
</style>
