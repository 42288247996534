import axios from 'axios'

const API_URL = (process.env.VUE_APP_API_HOST || '').replace(/\/$/, '')

let axiosInstance = axios.create({
    baseURL: API_URL,
    headers: (() => {
        if (localStorage.getItem('auth.token')) {
            return {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth.token')
            }
        }
        return {
            'Content-Type': 'application/json',
        }
    })()
})

axiosInstance.interceptors.response.use(function(response:any) {
    return response;
}, function(error:any) {
    if (error.response?.data?.message == 'Token has expired' && error.response.status == 401) {
        localStorage.setItem('auth.token', '');
        localStorage.setItem('auth.expires_in', '');
        localStorage.setItem('auth.user_id', '');
        localStorage.setItem('auth.user_role', '');
        localStorage.setItem('auth.user_name', '');
        window.location.href = '/';
    }
    return Promise.reject(error);
});

export default axiosInstance