export default {
    list: [{
            label: "Clientes",
            route: "dashboard.clients",
            icon: "fa fa-user-o",
            type: "item",
            roles: ['admin', 'operator']
        },
        {
            label: "Carteiras",
            route: "dashboard.wallets",
            icon: "fa fa-building-o",
            type: "item",
            roles: ['admin']
        },
        {
            label: "Atendimentos",
            route: "dashboard.calls",
            icon: "fa fa-phone",
            type: "item",
            roles: ['admin', 'operator']
        },
        {
            label: "Integração Copel",
            icon: "fa fa-bars",
            type: "list",
            roles: ['admin'],
            list: [{
                    label: "Remessa",
                    route: "dashboard.shipments",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Retorno",
                    route: "dashboard.returns",
                    icon: "fa fa-refresh",
                    roles: ['admin']
                }
            ]
        },
        {
            label: "Financeiro",
            route: "dashboard.finances",
            icon: "fa fa-file",
            type: "item",
            roles: ['admin']
        },
        {
            label: "Relatórios",
            icon: "fa fa-file-text-o",
            type: "list",
            roles: ['admin', 'operator'],
            list: [{
                    label: "Instituição",
                    route: "dashboard.report_institution",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Cadastro Instituições",
                    route: "dashboard.report_institution_create",
                    icon: "fa fa-arrow-right",
                    roles: ['admin', 'operator']
                },
                {
                    label: "Operadores",
                    route: "dashboard.report_operator",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Cancelados",
                    route: "dashboard.report_cancel",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Seguro",
                    route: "dashboard.report_safety",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Geral",
                    route: "dashboard.report_general",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
                {
                    label: "Parcelas Finais",
                    route: "dashboard.report_final_payments",
                    icon: "fa fa-arrow-right",
                    roles: ['admin']
                },
            ]
        },
        {
            label: "Usuários",
            route: "dashboard.users",
            icon: "fa fa-users",
            type: "item",
            roles: ['admin']
        }
    ]
}